<template>
	<v-card >
		<v-toolbar color="#D3D5D8"  dense> 
			<v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn>
			<v-spacer></v-spacer>
			<v-card-title>{{insumo.nombre | capitalize}}</v-card-title>
		</v-toolbar>
		<v-row class="no-mp">
			<v-col class="no-mp" >
				<v-card elevation="0" outlined style="margin:10px; padding:20px; background-color:#F0F0F0;">
					<v-form ref="form">
						<v-text-field v-model="insumo.nombre" :rules="reglas.nombre" label="Nombre"></v-text-field>
						<v-select v-model="insumo.presentacion" :rules="reglas.presentacion" label="Presentacion" :items="presentacion"></v-select>
						<v-text-field v-model="insumo.existencia" type="number" step="any" :rules="reglas.existencia" 
						label="Existencia Actual" :suffix="insumo.presentacion"> </v-text-field>
						<v-text-field v-model="insumo.precio" :rules="reglas.precio" label="Precio" prefix="$"></v-text-field>
						<v-text-field v-model="insumo.minimo" :rules="reglas.minimo" label="Existencia Minima"></v-text-field>
						<v-select v-model="insumo.almacen" :rules="reglas.almacen" label="Almacen" :items="almacenes" item-text="nombre" return-object></v-select>
						<v-select v-model="insumo.categoria" :rules="reglas.categoria" label="Categoria" :items="categorias" item-text="nombre" return-object></v-select>
						<v-text-field v-model="insumo.dias_caducidad" :rules="reglas.dias" label="Dias de Caducidad"></v-text-field>
					</v-form>					
					<v-btn block color="success" v-on:click="guardar">Guardar</v-btn>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	import Schema from '../../core/Schema';
  export default {
    components:{},
    props: { 
			insumo: {
				type:Object,
				default() { return { 
					id: null,
					nombre: null,
					existencia: null,
					almacen_id: null,
					almacen: null,
					categoria: null,
					minimo: null,
					presentacion: null,
					activo: 1,
					precio: null,
					dias_caducidad: null
				};}
			}
		},
    data () { return { 
			reglas: {
				nombre: [
					(val) => !!val || 'Agrgega el nombre del insumo'
				],
				existencia: [
					(val) => !!val || 'Cuantos insumos existen'
				],
				almacen_id: [
					(val) => !!val || 'Debes guardarlos en un almacen'
				],
				categoria: [
					(val) => !!val || 'Categoria del insumo'
				],
				minimo: [
					(val) => !!val || 'Cantidad minima en stock'
				],
				presentacion: [
					(val) => !!val || 'Selecciona la presentacion'
				],
				activo: 1,
				precio: [
					(val) => !!val || 'Precio de compra'
				],
				dias_caducidad: [
					(val) => !!val || 'Dias en que caduca'
				]
			},
			categorias:['RES', 'CERDO', 'LACTEOS', 'EMBUTIDOS','OTROS','PROMOCIONES','POLLO','PEZ','BIMBO','VERDURAS','ABARROTES'],
			almacenes:[],
			presentacion:['Caja','Bolsa','Pieza','Paquete','Kg','Bulto'],
		};},
		mounted() {
			this.$store.commit('setsplash',{texto:'Cargando', activar: true});
			Schema.models.Almacen.listar(null,null,(job) => {
				this.almacenes = job.newData;
				this.$store.commit('setsplash',{texto:'', activar: false});
			});
		},
    methods: {
			guardar() {
				if(!this.$refs.form.validate()) {
					//alert("Faltan agregar campos");
					return;
				}
				this.insumo.almacen_id = this.insumo.almacen.id;
				this.$store.commit('setsplash',{texto:'Guardando', activar: true});
				Schema.models.Insumo.guardar(this.insumo,'all',(job) => {
					job.newData['almacen'] = this.insumo.almacen;
					this.$emit('getinsumo',job.newData);
					this.$store.commit('setsplash',{texto:'', activar: false});
				});
				
			},
			cerrar() {
				this.$emit('getinsumo',null);
			},
		},
		computed: {},
		watch: {}
  }
</script>
<style>
</style>
