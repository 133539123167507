<template>
	<v-card style="padding:20px">
		<h4>Vincular insumo al proveedor: {{proveedor.nombre}}</h4>
		<v-row>
			<v-col><v-text-field v-model="buscar" append-icon="mdi-magnify" label="Buscar Insumo" single-line hide-details ></v-text-field></v-col>
		</v-row>
		<v-divider style="margin:20px"></v-divider>
		<v-data-table :headers="headers" :items="insumos" :search="buscar" >
			<template  v-slot:body="{ items }" >
        <tbody>
          <tr v-for="item in items" :key="item.id" v-on:click="vincular(item)">
            <td>{{ item.nombre }}</td>
            <td>{{ item.existencia }}</td>
            <td>{{ item.presentacion }}</td>
          </tr>
        </tbody>
      </template>
		</v-data-table>
	</v-card>
</template>

<script>
import Schema from '../../core/Schema';
export default {
  components: {},
  data: () => ({ 
		insumos:[],
		buscar:null,
		headers:[
			{ text: 'Nombre', align: 'start', value: 'nombre', },
			{ text: 'Existencia', value: 'existencia' },
			{ text: 'Presentacion', value: 'presentacion' },
		],
	}),
	props: {
		proveedor: {
			type:Object,
			default() { return null;}
		}
	},
	mounted() {
		this.$store.commit('setsplash',{texto:'Cargando', activar: true});
		Schema.models.Insumo.listar(null, null, (job) => {
			this.insumos = job.newData;
			this.$store.commit('setsplash',{texto:'', activar: false});
		});
	},
	methods: {
		vincular(i) {
			this.$store.commit('setsplash',{texto:'Vinculando producto', activar: true});
			let ip= {
				proveedor_id: this.proveedor.id,
				insumo_id: i.id,
			};
			Schema.models.InsumoProveedor.guardar(ip,'own', (job) => {
				this.$store.commit('setsplash',{texto:'', activar: false});
				job.newData['ip_insumo'] = i;
				this.$emit('getinsumoproveedor',job.newData);
			});
		}
	}
};
</script>
<style>
	.select:hover {
		cursor:pointer;
		background-color:#B7B3B3;
	}
</style>
