import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';

export default class Insumo extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = {
			id: {
        allowNull: false,
        autoIncrement: true,
        primaryKey: true,
        type: DataTypes['INTEGER']
      },
      nombre: {
				allowNull: false,
        type: DataTypes['STRING']
      },
      existencia: {
				allowNull: false,
        type: DataTypes['FLOAT'],
        defaultValue: 0
      },
      almacen_id: {
				allowNull: false,
        type: DataTypes['INTEGER'],
      },
      categoria: {
				allowNull: false,
        type: DataTypes['STRING'],
      },
      minimo: {
				allowNull: false,
        type: DataTypes['FLOAT'],
        defaultValue: 0
      },
      presentacion: {
				allowNull: false,
        type: DataTypes['ENUM']('Caja','Bolsa','Pieza','Paquete','Kg','Bulto'),
      },
      activo: {
				allowNull: false,
        type: DataTypes['BOOLEAN'],
        defaultValue: true
      },
      precio: {
				allowNull: false,
        type: DataTypes['FLOAT'],
        defaultValue: 0
      },
      dias_caducidad: {
				allowNull: false,
        type: DataTypes['INTEGER'],
        defaultValue: 0
      },
      created_at: {
        allowNull: true,
        type: DataTypes['DATE'],
        defaultValue: DataTypes['NOW']
      },
      updated_at: {
        allowNull: true,
        type: DataTypes['DATE'],
        defaultValue: DataTypes['NOW']
      }
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'insumos',
			modelName: 'Insumo',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [
			{model: this.sequelize.models.Almacen, as: 'almacen'}
		];
	}
}
