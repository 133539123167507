<template>
	<v-card min-height="80vh" elevation="0" style="background-color: transparent!important">
		<v-container  style="margin:0; padding:0; width:100%;">
			<v-row class="no-mp">
				<v-col class="no-mp" cols="12">
					<v-text-field v-model="valorBuscar" id="buscador" label="Buscar Insumo" solo
					v-on:keydown.enter="enfocarEn('prod_0')"></v-text-field>
				</v-col>
			</v-row>
			<v-row class="no-mp">
				<v-card style="height:205px; width:100%; overflow-x: hidden; overflow-y: scroll; background-color:#F8F8F8;">
					<v-card style="height: 6vh; padding:0; margin:3px 0 0 3px;" class="clicker" outlined 
					v-for="(p, index) in insumos" :key="index" v-on:click="selectInsumo(p)">
						<v-row class="no-mp" cols="9">
							<v-col style="padding:5px 0 0 5px; font-size:13px">
								<button :id="'prod_'+index"
								v-on:keydown.up.prevent="navegar(insumos.length, 'prod_', index-1)" 
								v-on:keydown.down.prevent="navegar(insumos.length, 'prod_', index+1)"
								v-on:keydown.enter="selectInsumo(p)" 
								v-on:click.stop="enfocarEn('prod_'+index)">
									{{p.nombre}}
								</button>
							</v-col>
							<v-col cols="3" style="padding:5px 0 0 5px; font-size:13px;" :key="'sin-cond'">
								 {{p.presentacion}}
							</v-col>
						</v-row>
					</v-card>
				</v-card>
			</v-row>
			<v-row class="no-mp">
				<v-col cols="12" style="margin: 8px 0 2px 0; padding:0">
					<v-text-field v-model="nota" :label="labelNota" id="nota" solo
					v-on:keydown.enter="enfocarEn('cantidad')" hide-details></v-text-field>
				</v-col>
			</v-row>
			<v-row class="no-mp">
				<v-col cols="6" style="margin: 8px 0 2px 0; padding:0">
					<v-select :items="itemsValorPrecio" :item-text="'nombre'"  v-model="agregarComo" solo hide-details
					:item-value="'valor'" label="Agregar como" ></v-select>
				</v-col>
				<v-col cols="6" style="margin: 8px 0 2px 0; padding:0">
					<v-text-field v-model="valorVolumen" label="Cantidad" id="cantidad" solo hide-details
					v-on:keydown.enter="agregarInsumo()" v-on:keydown.up="cambiarAgregarComo()"
					v-on:keydown.down="cambiarAgregarComo()" ></v-text-field>
				</v-col>
			</v-row>
			
			<v-row class="no-mp">
				<v-col cols="12" style="margin: 8px 0 2px 0; padding:0">
					<v-btn v-on:click="agregarInsumo()"  color="success" block> Agregar Insumo</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
	import Schema from '../../core/Schema.js';
	import Helper from '../../core/Helper.js';
	import Filter from '../../core/Filter.js';
	import navegable from '../../mixins/navegable.js';
	
  export default {
    mixins: [navegable],
    components:{},
    props: {
			cliente:{
				type:Object,
				default(){return null;}
			},
		},
    data: () => ({
			unsubscribe:null,
			insumoSelect: null,
			condicionProd:null,
			agregarComo:"cantidad",
			valorBuscar:null,
			valorVolumen:null,
			nota: null,
			insumos: [],
			itemsValorPrecio: [
				{nombre:"Precio $", valor:"precio"},
				{nombre:"Cantidad", valor:"cantidad"}
			],
		}),
		mounted() {
			Schema.models.Insumo.listar(30,1, job => {
				this.insumos = job.newData;
			});
		},
    methods: {
			buscar() {
				if(!this.valorBuscar || this.valorBuscar.length <= 0) {
					Schema.models.Insumo.listar(30,1,job => {
						this.insumos = job.newData;
					});
					return;
				}
				Schema.models.Insumo.buscarSimilar('nombre',this.valorBuscar, job => {
					this.insumos = job.newData;
				});
			},
			cambiarAgregarComo() {
				if(this.agregarComo == "cantidad") {
					this.agregarComo = "precio";
				} else {
					this.agregarComo = "cantidad";
				}
			},
			selectInsumo(p) {
				this.insumoSelect = p;
				console.log("se selecciona",this.insumoSelect);
				this.enfocarEn("nota");
			},
			calcularCantidad() {
				if(this.agregarComo == "cantidad") {
					return this.valorVolumen;
				} else {			
					return Number(this.valorVolumen) / Number(this.elPrecioChido(this.insumoSelect));
				}		
			},
			agregarInsumo() {
				if(!this.insumoSelect) {
					alert("Selecciona un insumo");
					return;
				}
				if(!this.valorVolumen || this.valorVolumen <= 0) {
					alert("Agrega una cantidad");
					return;
				}
				this.enfocarEn("buscador");
				this.$emit('getinsumoenvio',{
					cantidad: this.calcularCantidad(),
					insumo_id: this.insumoSelect.id,
					nota: this.nota,
					insumo: this.insumoSelect,
					envio_id: null
				});
				this.valorBuscar = null;
				this.valorVolumen = null;
				this.nota = null;
				this.insumoSelect = null;
			},
		},
		computed: {
			labelNota() {
				return 'Nota para ' + (this.insumoSelect?this.insumoSelect.nombre:'');
			}
		},
		watch: {
			valorBuscar() {
				this.buscar();
			}
		}
  }
</script>
<style>
	.span {
		width:100px;
		margin:2px;
		padding: 3px;
		border-radius: 10px;
		text-align: left;
	}
	
	.boton {
		width:210px;
		margin: 0px 2px 1px 10px;
		padding: 3px;
		border-radius: 10px;
		text-align: left;
	}
	.condicional {
		color:blue;
	}
</style>
