import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';
import Schema from '../core/Schema';

export default class InsumoEnvio extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = {
			id: {
        allowNull: false,
        primaryKey: true,
        autoIncrement: true,
        type: DataTypes['BIGINT']
      },
      insumo_id: {
				allowNull: false,
        type: DataTypes['STRING']
      },
      envio_id: {
				allowNull: false,
        type: DataTypes['BIGINT']
      },
      cantidad: {
				allowNull: false,
        type: DataTypes['FLOAT']
			},
      created_at: {
        allowNull: true,
        type: DataTypes['DATE']
      }
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'insumos_envios',
			modelName: 'InsumoEnvio',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [];
	}
	
	
}
