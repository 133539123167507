import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';

export default class Sucursal extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = {
			id: {
				allowNull: false,
				autoIncrement: true,
				primaryKey: true,
				type: DataTypes['INTEGER'],
			},
			nombre: {
				allowNull: false,
				type: DataTypes['STRING'](100),
			},
			direccion: {
				allowNull: false,
				type: DataTypes['STRING'](200),
			},
			telefono: {
				allowNull: false,
				type: DataTypes['STRING'](10),
			},
			encargado: {
				allowNull: false,
				type: DataTypes['STRING'],
			},
			rfc: {
				allowNull: false,
				type: DataTypes['STRING'],
			},
			tipo: {
				allowNull: false,
				type: DataTypes['ENUM']('caja','operatel'),
			},
			activa: {
				allowNull: false,
				type: DataTypes['BOOLEAN'],
				defaultValue: true,
			},
			socket_id: {
				allowNull: true,
				type: DataTypes['STRING'],
			},
			conectada: {
				allowNull: true,
				type: DataTypes['BOOLEAN'],
				defaultValue: false,
			},
			lat: {
				allowNull: true,
				type: DataTypes['FLOAT'](10,7),
			},
			lng: {
				allowNull: true,
				type: DataTypes['FLOAT'](10,7),
			},
			ultima_vez_conectado: {
				allowNull: true,
				type: DataTypes['DATE'],
				defaultValue: DataTypes['NOW']
			},
			created_at: {
				allowNull: true,
				type: DataTypes['DATE'],
				defaultValue: DataTypes['NOW']
			},
			updated_at: {
				allowNull: true,
				type: DataTypes['DATE'],
				defaultValue: DataTypes['NOW']
			}
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'sucursales',
			modelName: 'Sucursal',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [
			{model:this.sequelize.models.Asentamiento, as:'s_asentamientos'},
		];
	}
	
	estaConectada(nombre,handler) {
		this.attachSelectJob({nombre},'_estaConectada',handler);
	}
	
	_estaConectada(data) {
		
	}
  
}
