<template>
	<v-card >
		<v-toolbar color="#D3D5D8"  dense> 
			<v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn>
			<v-spacer></v-spacer>
			<v-card-title>{{cancelacion.nombre | capitalize}}</v-card-title>
		</v-toolbar>
		<div v-if= "sucursalConectada">
			<v-row class="no-mp" v-if="noTieneCancelacion">
				<v-col class="no-mp" >
					<v-card elevation="0" outlined style="margin:10px; padding:20px; background-color:#F0F0F0;">
						<v-form ref="form">
							<v-text-field solo label="Motivo" v-model="cancelacion.motivo"></v-text-field>
						</v-form>					
						<v-btn block color="success" v-on:click="guardar">Guardar</v-btn>
					</v-card>
				</v-col>
			</v-row>
			<v-row class="no-mp" v-else>
				<v-col class="no-mp" >
					<v-card elevation="0" outlined style="margin:10px; padding:20px; background-color:#F0F0F0;">
						<v-form ref="form">
							<h5>{{laCancelacion.motivo}}</h5>
						</v-form>					
						<v-btn block color="success" v-on:click="cerrar">Aceptar</v-btn>
					</v-card>
				</v-col>
			</v-row>
		</div>
		
		<v-row class="no-mp" v-else>
			<v-col class="no-mp" >
				<v-card elevation="0" outlined style="margin:10px; padding:20px; background-color:#F0F0F0;">
					<v-form ref="form">
						<h5>No se pueden hacer cancelaciones hasta que un usuario inicie la aplicacion de Escritorio</h5>
					</v-form>					
					<v-btn block color="success" v-on:click="cerrar">Aceptar</v-btn>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import Schema from '../../core/Schema';
 export default {
   components:{},
   props: { 
		pedido: {
			type:Object,
			default() { return null;}
		}
	},
   data () { return { 
		cancelacion: {
			pedido_id:null,
			motivo:null,
			user_id:null,
		},
		sucursalSelect: null,
		sucursales:[],
		rules: {},
		unsubscribe: null,
	};},
	created() {
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'setserverdata' && state.server_data.model == 'Sesion') {
				//this.revisarSesiones();
			}
		});
	},
	beforeDestroy() {
		this.unsubscribe();
	},
	mounted() {
		this.revisarSesiones();
	},
	methods: {
		revisarSesiones() {
			this.$store.commit('setsplash',{texto:'Cargando...', activar: true});
			Schema.models.Sucursal.listar(null, null, job => {
				console.log(job);
				this.sucursales = job.newData;
				this.$store.commit('setsplash',{texto:'', activar: false});
			});
		},
		guardar() {
			if(!this.cancelacion.motivo || this.cancelacion.motivo.length <= 0) {
				alert("Faltan agregar campos");
				return;
			}
			this.cancelacion.pedido_id = this.pedido.id,
			this.cancelacion.user_id = this.$store.state.user.id,
			this.$store.commit('setsplash',{texto:'Guardando', activar: true});
			Schema.models.Cancelacion.crearCancelacion(this.cancelacion, this.sucursalSelect.socket_id, (job) => {
				this.pedido.cancelado = 1;
				this.pedido['cancelacion'] = [job.newData.cancelacion];
				this.$emit('getcancelacion',job.newData.cancelacion,this.pedido);
				this.$store.commit('setsplash',{texto:'', activar: false});
			});
		},
		cerrar() {
			this.$emit('getcancelacion',null);
		}
	},
	computed: {
		laCancelacion() {
			return this.pedido.cancelacion[0];
		},
		noTieneCancelacion() {
			return !this.pedido.cancelacion || this.pedido.cancelacion.length <= 0;
		},
		sucursalConectada() {
			let activa = false;
			this.sucursales.forEach((item) => {
				if(this.pedido.sucursal_id == item.id) {
					activa = item.conectada;
					this.sucursalSelect = item;
				}
			});
			return activa;
		},
	},
	watch: {}
 }
</script>
<style>
</style>
