import Schema from '../core/Schema';
//import BD from '../core/BD';
import Helper from '../core/Helper';
//import Server from '../core/Server';
import Auth from '../core/Auth';
import store from './store';

import Pedido from '../models/Pedido';
import Cliente from '../models/Cliente';
import Sucursal from '../models/Sucursal';
import Asentamiento from '../models/Asentamiento';
import TipoCliente from '../models/TipoCliente';
import Producto from '../models/Producto';
import Precio from '../models/Precio';
import User from '../models/User';
import ItemPedido from '../models/ItemPedido';
import PagoPedido from '../models/PagoPedido';
import Dia from '../models/Dia';
import Cancelacion from '../models/Cancelacion';

//inventario
import Almacen from '../models/Almacen';
import Compra from '../models/Compra';
import Entrada from '../models/Entrada';
import Insumo from '../models/Insumo';
import InsumoProveedor from '../models/InsumoProveedor';
import Proveedor from '../models/Proveedor';
import Salida from '../models/Salida';
import Envio from '../models/Envio';
import InsumoEnvio from '../models/InsumoEnvio';
import CancelacionEnvio from '../models/CancelacionEnvio';

export default {
	
	async start() {
		//let config = Helper.leerJsonExterno('../config.conf'); //<--- no se puede usar en una spa
		//let config = {server:'http://localhost:6002'};
		//let config = {server:'http://api-cruvi.carnescruvi.com.mx'};
		//let config = {server:'http://api-consejo.consejoempresarial.site'};
		let config = {server:'http://api-cruvi.mundosica.com'};
		console.log(config);
		Schema.loadModels = this.loadModels;
		Schema.loadAssociations = this.loadAssociations;
		Schema.onConnect = this.onConnect;
		Schema.sincronization = this.sincronization;
		Schema.onDisconnect = this.onDisconnect;
		await Schema.initInClient(null, false ,config);
		Schema.Auth.setStore(store,'setserverdata');
		Schema.addStoreHook(store,'setserverdata');
		return config;
	},
	close() { },
	
	onConnect(socket) { },
	
	onDisconnect(socket) { },
	
	async sincronization(sucursal_id, socket) { },
	
	loadModels() {
		let config = {nodo:"principal"};
		Schema.addModel('Pedido',Pedido, config);
		Schema.addModel('Cliente',Cliente, config);
		Schema.addModel('Asentamiento',Asentamiento, config);
		Schema.addModel('Sucursal',Sucursal, config);
		Schema.addModel('TipoCliente',TipoCliente, config);
		Schema.addModel('Producto',Producto, config);
		Schema.addModel('Precio',Precio, config);
		Schema.addModel('User',User, config);
		Schema.addModel('ItemPedido',ItemPedido, config);
		Schema.addModel('PagoPedido',PagoPedido, config);
		Schema.addModel('Dia',Dia, config);
		Schema.addModel('Cancelacion',Cancelacion, config);
		
		Schema.addModel('Almacen',Almacen, config);
		Schema.addModel('Compra',Compra, config);
		Schema.addModel('Entrada',Entrada, config);
		Schema.addModel('Insumo',Insumo, config);
		Schema.addModel('InsumoProveedor',InsumoProveedor, config);
		Schema.addModel('Proveedor',Proveedor, config);
		Schema.addModel('Salida',Salida, config);
		Schema.addModel('Envio',Envio, config);
		Schema.addModel('InsumoEnvio',InsumoEnvio, config);
		Schema.addModel('CancelacionEnvio',CancelacionEnvio, config);
	},
	
	loadAssociations() { }
}

