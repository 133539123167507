import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';

export default class InsumoProveedor extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = {
			id: {
        allowNull: false,
        autoIncrement: true,
        primaryKey: true,
        type: DataTypes['INTEGER']
      },
      proveedor_id: {
				alluwNull: false,
        type: DataTypes['INTEGER']
      },
      insumo_id: {
				alluwNull: false,
        type: DataTypes['INTEGER']
      },
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'insumos_proveedores',
			modelName: 'InsumoProveedor',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [
			{model: this.sequelize.models.Insumo, as: 'ip_insumo'},
			{model: this.sequelize.models.Proveedor, as: 'ip_proveedor'},
		];
	}
}
