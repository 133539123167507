import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';
import Schema from '../core/Schema';

export default class Envio extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = {
			id: {
        allowNull: false,
        primaryKey: true,
        autoIncrement: true,
        type: DataTypes['BIGINT']
      },
      cajero_id: {
				allowNull: false,
        type: DataTypes['INTEGER']
      },
      sucursal_id: {
				allowNull: false,
        type: DataTypes['INTEGER']
      },
      tipo: {
				allowNull: false,
        type: DataTypes['ENUM']('peticion','confirmacion')
      },
      created_at: {
        allowNull: true,
        type: DataTypes['DATE']
      },
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'envios',
			modelName: 'Envio',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [
			{model: this.sequelize.models.InsumoEnvio, as: 'insumos', include:[{model: this.sequelize.models.Insumo, as : 'insumo'}]},
			{model: this.sequelize.models.Sucursal, as: 'sucursal'},
			{model: this.sequelize.models.CancelacionEnvio, as: 'cancelacion_envio'},
		];
	}
	
	crearEnvio(envio,items, handler) {
		this.attachExecuteJob({envio,items},'_saveEnvio','all',handler);
	}
  //
  
  async _saveEnvio(data) {
		let p = await this._save(data.envio);
		let items = [];
		for(let i = 0; i < data.items.length; i++) {
			let item = data.items[i];
			item.envio_id = p.id;
			items.push(item);
		}
		items = await Schema.models.InsumoEnvio._saveAll(items,p.sucursal_id);
		return {envio:p, items};
	}
	
}
