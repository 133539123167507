<template>
  <v-card  class="pa-1" outlined tile >
		<v-simple-table height="230px">
			<template v-slot:default>
				<thead>
					<tr>
						<th class="text-left"></th>
						<th class="text-left">Nombre</th>
						<th class="text-left">Cantidad</th>
						<th class="text-left">Total</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(i, index) in items" :key="index">
						<td><v-chip dark color="red" v-on:click="borrar(index,i)" > <i class="fa fa-times"></i></v-chip></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
	</v-card>
</template>

<script>
		import Schema from '../../core/Schema';
	
  export default {
    components:{},
    props: {
			items: {
				type: Array,
				default() {return [];},
			}
		},
    data: () => ({ }),
		mounted() {},
    methods: {
			cantidad(p) {
				return Number(p.cantidad).toFixed(2);
			},
			borrar(index, item) {
				if(!!item.id) {
					Schema.models.InsumoEnvio.borrar(item.id,'own',job => {});
				}
				this.items.splice(index,1);
			},
			
		},
		watch: {},
		computed: {}
	}
</script>
<style>
</style>
