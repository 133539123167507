import { DataTypes, Op } from '../utils/DataTypes';
import AppModel from '../core/AppModel';
import Helper from '../core/Helper';

export default class Almacen extends AppModel {
  constructor(sequelize, origin,crud) {
    super(sequelize, origin,crud);
    this.atributos = {
			id: {
        allowNull: false,
        autoIncrement: true,
        primaryKey: true,
        type: DataTypes['INTEGER']
      },
      nombre: {
				allowNull: false,
        type: DataTypes['STRING'](100)
      },
      descripcion: {
				allowNull: true,
        type: DataTypes['TEXT']
      },
      created_at: {
        allowNull: true,
        type: DataTypes['DATE']
      },
      updated_at: {
        allowNull: true,
        type: DataTypes['DATE'],
        defaultValue: DataTypes['NOW']
      }
		};
		this.fieldsValids = Object.keys(this.atributos);
		this.options = {
      tableName: 'almacenes',
			modelName: 'Almacen',
			timestamps: false,
			freezeTableName: true,
			underscored: true,
      // Hooks: https://sequelize.org/master/manual/hooks.html
    };
    if(this.crud) {
			this.model = sequelize.define(this.options.modelName, this.atributos, this.options);
		}
  }// constructor.
  
  includeAssociations() {
		return [];
	}
}
