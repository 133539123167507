<template>
	<v-card width="100%">
		<v-row class="no-mp">
			<v-col class="no-mp" cols="7"></v-col>
			<v-col class="no-mp" cols="5">
				<v-btn v-on:click="insumoSearch = true;">Vincular Insumo a Proveedor</v-btn>
			</v-col>
		</v-row>
		<v-row class="no-mp">
			<v-col cols="3">Producto</v-col>
			<v-col cols="3">Precio compra</v-col>
			<v-col cols="3">Cantidad</v-col>
			<v-col cols="3">Total</v-col>
		</v-row>
		<v-row class="no-mp" v-for="i,index in entradas" :key="insumos_proveedor.id">
			<v-col cols="3"><v-chip>{{i.insumo.nombre}}</v-chip></v-col>
			<v-col cols="3"><v-text-field v-model="i.precio" hide-details prefix="$" v-on:keyup="calcularTotal"></v-text-field></v-col>
			<v-col cols="3"><v-text-field v-model="i.cantidad" hide-details v-on:keyup="calcularTotal" ></v-text-field></v-col>
			<v-col cols="3">$ {{totalEntrada(i) | moneda}}</v-col>
		</v-row>
		<v-dialog v-model="insumoSearch">
			<InsumoSearch :proveedor="proveedor" v-on:getinsumoproveedor="getInsumoProveedor" />
		</v-dialog>
	</v-card>
</template>

<script>
import Schema from '../../core/Schema';
import InsumoSearch from '../insumos/InsumoSearch';
export default {
   components:{InsumoSearch},
   props: { 
		insumos_proveedor: {
			type:Array,
			default() { return [];}
		},
		proveedor: {
			type:Object,
			default() { return null;}
		}
	},
	data () { return {  
		insumoSearch:false,
		entradas:[],
	};},
	mounted() {
		this.generarEntradasVacias();
	},
  methods: {
		calcularTotal() {
			this.$emit('getentradas',this.entradas);
		},
		totalEntrada(i) {
			return Number(i.cantidad) * Number(i.precio);
		},
		getInsumoProveedor(ip) {
			this.insumos_proveedor.push(ip);
			this.entradas.push({
				id:null,
				insumo: ip.ip_insumo,
				insumo_id: ip.insumo_id,
				cantidad: 0,
				precio: ip.ip_insumo.precio,
				compra_id: null,
			});
			this.insumoSearch = false;
		},
		generarEntradasVacias() {
			this.entradas = [];
			this.insumos_proveedor.forEach((item) => {
				console.log("creando entrada para", item);
				this.entradas.push({
					id:null,
					insumo: item.ip_insumo,
					insumo_id: item.insumo_id,
					cantidad: 0,
					precio: item.ip_insumo.precio,
					compra_id: null,
				});
			});
		},
	},
	computed: {},
	watch: {
		proveedor() {
			this.generarEntradasVacias();
		}
	}
}
</script>
<style>
</style>
