<template>
	<v-card height="100%" tile>
		<v-toolbar class="sub-cabecera" dark  dense> 
			<!--v-btn icon v-on:click="cerrar" > <i class="fa fa-times"></i></v-btn-->
			<v-spacer></v-spacer>
			<v-card-title>Compras a proveedores</v-card-title>
		</v-toolbar>
		<v-card-text>
			<h3>Infomacion de la compra</h3>
			<v-divider></v-divider>
			<v-row class="no-mp">
				<v-col cols="3">
					<v-row class="no-mp">
						<v-text-field label="Folio de la Factura" outlined v-model="compra.folio" ></v-text-field>
						<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
							offset-y min-width="auto" >
							<template v-slot:activator="{ on, attrs }">
								<v-text-field  label="Fecha de la compra" outlined v-model="compra.fecha"  readonly v-bind="attrs" v-on="on"
								></v-text-field>
							</template>
							<v-date-picker  @input="menu2 = false" v-model="compra.fecha"></v-date-picker>
						</v-menu>
						<v-card style="padding:20px;font-size:17px; text-align:center">
							<div >Total de la Compra </div>
							<div>$ {{totalCompra | moneda}}</div>
							<div><v-btn color="success" block v-on:click="guardarCompra">Guardar Compra</v-btn></div>
						</v-card>
						<v-card style="padding:20px; margin:10px 0 10px 0" v-if="proveedorSelect">
							<h4>Proveedor Seleccionado:</h4>
							<div>{{proveedorSelect.nombre}}</div>
							<div>{{proveedorSelect.rfc}}</div>
							<div>{{proveedorSelect.telefono}}</div>
							<div>{{proveedorSelect.email}}</div>
						</v-card>
					</v-row>
				</v-col>
				<v-col cols="9">
					<v-row class="no-mp">
						<v-autocomplete  :items="proveedores"  item-text="nombre" label="Proveedor" v-model="proveedorSelect" 
						return-object></v-autocomplete>
					</v-row>
					<v-row class="no-mp" v-if="!!proveedorSelect">
						<EntradasFormList  :proveedor="proveedorSelect" :insumos_proveedor="proveedorSelect.p_ip" v-on:getentradas="getEntradas"/>
					</v-row>
				</v-col>
			</v-row>
			
			<v-divider></v-divider>
		</v-card-text>
	</v-card>
</template>

<script>
	import Schema from '../core/Schema';
	import EntradasFormList from '../reutilizables/entradas/EntradasFormList';
  export default {
    components:{ EntradasFormList},
    props: { },
    data () { return { 
			menu2:false,
			compra: {
				fecha:null,
				total:0,
				pagado:0,
				factura:null,
			},
			proveedores:[],
			proveedorSelect:null,
			entradas:[],
		};},
		mounted() { 
			this.$store.commit('setsplash',{texto:'Cargando', activar: true});
			Schema.models.Proveedor.listar(null,null,job => {
				this.proveedores = job.newData;
				this.$store.commit('setsplash',{texto:'', activar: false});
			});
		},
    methods: {
			getEntradas(e) {
				this.entradas = e;
			},
			guardarCompra() {
				if(!this.compra.fecha) {
					alert("Agrega la fecha de la compra");
					return;
				}
				if(this.compra.total <= 0) {
					alert("No se agrego ninguna entrada");
					return;
				}
				this.$store.commit('setsplash',{texto:'Guardando compra', activar: true});
				Schema.models.Compra.guardar(this.compra,'own',job => {
					let c = job.newData;
					let insumosActualizar = [];
					for(let i = 0; i < this.entradas.length; i++) {
						this.entradas[i].compra_id = c.id;
						let insumo = this.entradas[i].insumo;
						if(Number(this.entradas[i].cantidad) > 0) {
							insumo.existencia = Number(insumo.existencia) + Number(this.entradas[i].cantidad);
							insumo.precio = Number(this.entradas[i].precio);
							insumosActualizar.push(insumo);
						}
					}
					Schema.models.Entrada.guardarTodos(this.entradas,'own',job => { 
						Schema.models.Insumo.guardarTodos(insumosActualizar,'own',job => { 
							this.proveedorSelect = null;
							this.$store.commit('setsplash',{texto:'', activar: false});
							this.compra = {
								fecha:null,
								total:0,
								pagado:0,
								factura:null,
							};
							this.entradas = [];
							alert("Compra guardada");
						});
						
					});
				});
			},
		},
		computed: {
		totalCompra() {
			this.compra.total = 0;
			this.entradas.forEach((item) => {
				this.compra.total += Number(item.precio) * Number(item.cantidad);
			});
			
			return this.compra.total;
		}
	},
		watch: {}
  }
</script>
<style>
</style>
